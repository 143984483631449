const exampleQuery =
    ">example\n" +
    "ATTTCGACGCTCAAGGAGTCGCTGATAGGTGACCGTATTACTCGAATCGAAGGGATATTA\n" +
    "AACGGCCCTGAATTACATTCTCACTGAGATGGAGGAAGAGGGGGCTTCATTCTCTGAGGC\n" +
    "GCTGAAGGAGGCACAGCAATTGGGCTACGCGGAAGCGGATCCTACGGACGATGTGGAAGG\n" +
    "GCTAGATGCTGCTAGAAAGCTGGCAATTCTAGCCAGATTGGCATTTGGGTTAGAGGTCGA\n" +
    "GTTGGAGGACGTAGAGGTGGAAGCAATTGAAAAGCTGACTGCCGAAGATATTGAAAAAAT\n" +
    "AAAGGAAGAGGGTAAAGTTTTAAAACTAGTGGCAAGCGCCGTCGAAGCCAGGGTCAAGCC\n" +
    "TGAGCTGGTACCTAAGTCACATCCATTAGCCTCGGTAAAAGGCTCTGACAACGCCGTGGC\n" +
    "TGTAGAAACGGAACGACATTCTGGTAGGCGAACTCGTAGTGCAGGGACCAGGGGCTGGCG\n" +
    "AAGAGCCAACCGCATCCGCTGTACTCGCT\n"

export default exampleQuery;
